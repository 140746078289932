/*! 2025-4-2 20:17:05 */
[data-v-1f7d4e58]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}[data-v-1f7d4e58] .el-dialog__header{text-align:left}.line-one[data-v-1f7d4e58]{width:125px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.packing-detail-wrapper[data-v-1f7d4e58]{display:flex;align-items:flex-end}.packing-detail-wrapper .content[data-v-1f7d4e58]{flex:1;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;margin-right:5px}.packing-detail-wrapper .item[data-v-1f7d4e58]{width:20px}.el-icon-edit[data-v-1f7d4e58]{cursor:pointer;color:#409eff;cursor:'pointer'}


input[type='number'] {
  -moz-appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[data-v-750751bd]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.sum[data-v-750751bd]{margin-top:10px;text-align:right}.sum span[data-v-750751bd]{margin-right:1px}.sum span.sum1[data-v-750751bd]{margin-right:15px}.sum span.sum2[data-v-750751bd]{margin-right:20px}.bom[data-v-750751bd]{width:100%;height:100%;padding-bottom:60px;position:relative}.bom .L[data-v-750751bd]{width:58%;padding:0 10px 0 20px}.bom .M[data-v-750751bd]{display:flex;align-items:center}.bom .R[data-v-750751bd]{width:calc(42% - 50px);padding:0 20px}.bom .btns[data-v-750751bd]{position:absolute;bottom:0;left:0;width:100%;height:60px;text-align:right;padding:14px 20px;border-top:1px solid #ededed}.bom .el-tabs .el-button[data-v-750751bd]{height:34px !important}.bom .el-tabs .el-table[data-v-750751bd]{height:calc(100vh - 220px);overflow-y:auto}.bom .el-tabs .el-pagination[data-v-750751bd]{height:40px}.bom .el-form[data-v-750751bd]{height:calc(100vh - 149px);overflow-y:auto}.bom .img-wrap[data-v-750751bd]{position:relative;padding:3px 0;width:50px;margin:0 auto}.bom .img-wrap .el-image[data-v-750751bd]{width:50px;height:50px;-o-object-fit:cover;object-fit:cover;display:block}.bom .img-wrap .el-badge[data-v-750751bd]{position:absolute;top:3px;right:-10px;z-index:2}


/*# sourceMappingURL=chunk-26ae8ded.f3ec9186.css.map*/